import React from "react";

import MainPage from '../../Components/MainPage/MainPage'

const Main = () => {
  return (
    <MainPage />
  );
};

export default Main;
