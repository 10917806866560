import React from "react";

import Header from "../../Components/Header/Header";

import { MdArrowOutward } from "react-icons/md";

import "./style.css";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

const Help = () => {
  return (
    <div className="help__container">
      <Header className="custom-header" />
      <Breadcrumbs/>
      <h2 className="help__title">
        Роз'яснення куди звертатися у разі зникнення зв’язку із захисником
      </h2>
      <a className="help-link" target="blanc" href="https://minre.gov.ua/">
        Міністерство з питань реінтеграції тимчасово окупованих територій
        України <MdArrowOutward className="arrow" />
      </a>
      <div className="help__text">
        <p>
          Постійний зв’язок з військовослужбовцем – це те, що заспокоює його
          близьких та рідних. Але виникають ситуації, коли через об’єктивні
          причини зв’язок із бійцем втрачається.
        </p>
        <p>
          Якщо зв’язок з військовим перервався без попередження та відсутній
          довше ніж зазвичай, його рідні мають зробити наступне:
        </p>

        <ul>
          <li>
            Немає зв’язку понад 3 дні – звернутися до його безпосереднього
            командира.
          </li>
          <li>
            Відсутній зв’язок із безпосереднім командиром – звернутися до
            командира частини чи до його заступника з морально-психологічного
            забезпечення.
          </li>
          <li>
            Втрачено контакт з військовослужбовцем більш ніж 10 днів, а зв’язку
            з командиром немає – звернутися до Територіального центру
            комплектування та соціальної підтримки за місцем призову захисника.
          </li>
        </ul>
        <p>
          Щоб зменшити ризик втрати контакту, у МОУ радять військовим та їхнім
          родинам попередньо домовитися щодо правил зв’язку, зокрема:
        </p>
        <ul>
          <li>визначити періодичність, з якою відбуватиметься контакт;</li>
          <li>домовитись, хто телефонує першим та у який час;</li>
          <li>
            обговорити, за яких обставин рідним захисника слід звертатися до
            його командирів чи бойових побратимів.
          </li>
        </ul>
        <p>
          Увага! У разі виникнення додаткових питань рідні можуть звернутися на
          гарячу лінію Міноборони за номером: 0 800 500 442.
        </p>
      </div>
    </div>
  );
};

export default Help;
