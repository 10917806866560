import React from "react";

import Page1 from "../../Components/Page1/Page1";

const Person = () => {
  return (
    <Page1 />
  );
};

export default Person;
